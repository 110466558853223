<template>
  <div class="registerUserMgrList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-datepicker label="注册时间" type="rangedatetimer" :startTime.sync="searchParams.startRegisterTime" :endTime.sync="searchParams.endRegisterTime"></v-datepicker>
        <v-input label="姓名" v-model="searchParams.userName"></v-input>
        <v-input label="手机号" v-model="searchParams.mobileNum"></v-input>
        <v-input label="app版本" v-model="searchParams.appVersion"></v-input>
        <v-select label="实名认证" v-model="searchParams.realnameExamine" :options="realnameExamineOps"></v-select>
        <v-datepicker label="启动时间段" :startTime.sync="searchParams.startLatestAccessDate" :endTime.sync="searchParams.endLatestAccessDate"></v-datepicker>
        <v-datepicker label="注销时间" type="rangedatetimer" :startTime.sync="searchParams.startDeleteTime" :endTime.sync="searchParams.endDeleteTime"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <!-- <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button> -->
        <v-button text="查看" type="text" permission="update" @click="view(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL } from './api'
import { realnameExamineMap, realnameExamineOps, adminStatusMap } from './map'
import moment from 'moment'

export default {
  name: 'RegisterUserMgrList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      realnameExamineOps,
      searchParams: {
        userName: '',
        mobileNum: '',
        appVersion: '',
        realnameExamine: undefined,
        startRegisterTime: '',
        endRegisterTime: '',
        startLatestAccessDate: '',
        endLatestAccessDate: '',
        startDeleteTime: '',
        endDeleteTime: ''
      },
      headers: [
        {
          prop: 'userName',
          label: '姓名'
        },
        {
          prop: 'mobileNum',
          label: '手机号'
        },
        {
          prop: 'realnameExamine',
          label: '实名认证',
          formatter (row) {
            return realnameExamineMap[row.realnameExamine]
          }
        },
        {
          prop: 'intime',
          label: '注册时间'
        },
        {
          prop: 'deleteTime',
          label: '注销时间'
        },
        {
          prop: 'latestAccessDate',
          label: '启动时间'
        },
        {
          prop: 'accessCnt',
          label: '总启动次数',
          align: 'right'
        },
        {
          prop: 'appVersion',
          label: 'app版本'
        },
        {
          prop: 'mobileName',
          label: '设备型号'
        },
        {
          prop: 'adminStatus',
          label: '状态',
          formatter: row => adminStatusMap[row.adminStatus]
        }
      ]
    }
  },
  created () {
    let format = 'YYYY-MM-DD HH:mm'
    this.searchParams.startRegisterTime = moment().subtract(7, 'days').format(format)
    this.searchParams.endRegisterTime = moment().format(format)
  },
  methods: {
    create () {
      this.$router.push({
        name: 'registerUserMgrForm'
      })
    },
    // edit (row) {
    //   this.$router.push({
    //     name: 'registerUserMgrForm',
    //     query: {
    //       id: row.id
    //     }
    //   })
    // },
    view (row) {
      this.$router.push({
        name: 'registerUserMgrView',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>
