var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "registerUserMgrList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          exportPermission: "export",
          searchUrl: _vm.searchUrl,
          exportUrl: _vm.exportUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "新增", permission: "add" },
                  on: { click: _vm.create },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-datepicker", {
                  attrs: {
                    label: "注册时间",
                    type: "rangedatetimer",
                    startTime: _vm.searchParams.startRegisterTime,
                    endTime: _vm.searchParams.endRegisterTime,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "startRegisterTime",
                        $event
                      )
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "startRegisterTime",
                        $event
                      )
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "endRegisterTime",
                        $event
                      )
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "endRegisterTime",
                        $event
                      )
                    },
                  },
                }),
                _c("v-input", {
                  attrs: { label: "姓名" },
                  model: {
                    value: _vm.searchParams.userName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "userName", $$v)
                    },
                    expression: "searchParams.userName",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "手机号" },
                  model: {
                    value: _vm.searchParams.mobileNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "mobileNum", $$v)
                    },
                    expression: "searchParams.mobileNum",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "app版本" },
                  model: {
                    value: _vm.searchParams.appVersion,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "appVersion", $$v)
                    },
                    expression: "searchParams.appVersion",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "实名认证", options: _vm.realnameExamineOps },
                  model: {
                    value: _vm.searchParams.realnameExamine,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "realnameExamine", $$v)
                    },
                    expression: "searchParams.realnameExamine",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    label: "启动时间段",
                    startTime: _vm.searchParams.startLatestAccessDate,
                    endTime: _vm.searchParams.endLatestAccessDate,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "startLatestAccessDate",
                        $event
                      )
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "startLatestAccessDate",
                        $event
                      )
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "endLatestAccessDate",
                        $event
                      )
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "endLatestAccessDate",
                        $event
                      )
                    },
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    label: "注销时间",
                    type: "rangedatetimer",
                    startTime: _vm.searchParams.startDeleteTime,
                    endTime: _vm.searchParams.endDeleteTime,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "startDeleteTime",
                        $event
                      )
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "startDeleteTime",
                        $event
                      )
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endDeleteTime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endDeleteTime", $event)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { text: "查看", type: "text", permission: "update" },
                  on: {
                    click: function ($event) {
                      return _vm.view(scope.row)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }